import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import eventBus from "./utils/eventBus";

gsap.registerPlugin(ScrollTrigger);

var tl = gsap.timeline();

// tl.from(".fade-1", { delay: 0.2, duration: 1, opacity: 0 });
// tl.from(".fade-2", { delay: 0.4, duration: 1, opacity: 0 });
// tl.from(".fade-3", { delay: 0.6, duration: 1, opacity: 0 });
// tl.from(".fade-4", { delay: 0.8, duration: 1, opacity: 0 });
// tl.from(".fade-5", { delay: 1, duration: 1, opacity: 0 });

export default function Animations() {
  gsap.from(".intro-title", {
    duration: 1,
    opacity: 0,
    scrollTrigger: {
      trigger: ".intro-title",
      start: "top 90%",
      end: "top 70%",
    },
  });

  gsap.from(".intro-subtitle", {
    duration: 1,
    x: "-120%",
    scrollTrigger: {
      trigger: ".intro-subtitle",
      start: "top 95%",
      end: "top 75%",
    },
  });

  gsap.from(".intro-des", {
    duration: 1,
    opacity: 0,
    scrollTrigger: {
      trigger: ".intro-des",
      start: "top 80%",
      end: "top 70%",
    },
  });

  //  Image Text

  gsap.from(".imagetext-title", {
    duration: 1,
    opacity: 0,
    scrollTrigger: {
      trigger: ".imagetext-title",
      start: "top 80%",
      end: "top 70%",
    },
  });
  gsap.from(".imagetext-des", {
    duration: 1,
    opacity: 0,
    scrollTrigger: {
      trigger: ".imagetext-des",
      start: "top 70%",
      end: "top 50%",
    },
  });

  gsap.from(".imagetext-svg", {
    duration: 2,
    x: "200px",
    y: "-200px",
    scrollTrigger: {
      trigger: ".imagetext-svg",
      start: "top 90%",
      end: "top 50%",
    },
  });

  gsap.from(".imagetext-svg2", {
    duration: 2,
    opacity: 0,
    scrollTrigger: {
      trigger: ".imagetext-svg2",
      start: "top 70%",
      end: "top 50%",
    },
  });

  gsap.from(".imagetext-title2", {
    duration: 1,
    opacity: 0,
    scrollTrigger: {
      trigger: ".imagetext-title2",
      start: "top 80%",
      end: "top 70%",
    },
  });
  gsap.from(".imagetext-des2", {
    duration: 1,
    opacity: 0,
    scrollTrigger: {
      trigger: ".imagetext-des2",
      start: "top 70%",
      end: "top 50%",
    },
  });

  // SVG Animation

  gsap.from(".svg-li", {
    duration: 1,
    opacity: 0,
    scrollTrigger: {
      trigger: ".svg-li",
      start: "top 65%",
      end: "top 50%",
    },
  });
  gsap.from(".svg-li2", {
    duration: 1,
    opacity: 0,
    scrollTrigger: {
      trigger: ".svg-li2",
      start: "top 55%",
      end: "top 45%",
    },
  });

  gsap.from(".line-1-animate", {
    opacity: 0,
    scrollTrigger: {
      trigger: ".line-1-animate",
      start: "top 60%",
      end: "top 40%",
    },
  });

  gsap.from(".line-2-animate", {
    opacity: 0,
    scrollTrigger: {
      trigger: ".line-2-animate",
      start: "top 90%",
      end: "top 70%",
    },
  });

  gsap.from(".border-animate", {
    opacity: 0,
    scrollTrigger: {
      trigger: ".border-animate",
      start: "top 80%",
      end: "top 60%",
      scrub: 1,
    },
  });

  gsap.from(".play-animate", {
    opacity: 0,
    scrollTrigger: {
      trigger: ".play-animate",
      start: "top 60%",
      end: "top 50%",
      scrub: 1,
    },
  });

  gsap.from(".line-2-animate", {
    opacity: 0,
    scrollTrigger: {
      trigger: ".line-2-animate",
      start: "top 60%",
      end: "top 40%",
      scrub: 1,
    },
  });

  gsap.from(".svg-left-fade", {
    duration: 1,
    x: "200px",
    scrollTrigger: {
      trigger: ".svg-left-fade",
      toggleActions: "play none none none",
      start: "top 90%",
      end: "top 60%",
    },
  });

  gsap.from(".battery-svg", {
    duration: 1,
    opacity: 0,
    scrollTrigger: {
      trigger: ".battery-svg",
      toggleActions: "play none none none",
      start: "top 60%",
      end: "top 50%",
    },
  });

  gsap.from(".switchboard-svg", {
    duration: 1,
    opacity: 0,
    scrollTrigger: {
      trigger: ".switchboard-svg",
      toggleActions: "play none none none",
      start: "top 70%",
      end: "top 60%",
    },
  });

  gsap.from(".svg-right-fade", {
    duration: 1,
    y: "-200px",
    scrollTrigger: {
      trigger: ".svg-right-fade",
      toggleActions: "play none none none",
      start: "top 100%",
      end: "top 70%",
    },
  });
}

//faqs

gsap.from(".faqs-title", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".faqs-title",
    start: "top 75%",
    end: "top 60%",
  },
});

gsap.from(".faqs-subtitle", {
  duration: 1,
  x: "-120%",
  scrollTrigger: {
    trigger: ".faqs-subtitle",
    start: "top 90%",
    end: "top 75%",
  },
});

gsap.from(".faqs-abstract", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".faqs-abstract",
    start: "top 70%",
    end: "top 50%",
  },
});

const cursor = document.querySelector("#cursor");
let mouse = { x: 300, y: 300 };
let pos = { x: 0, y: 0 };
const speed = 0.1; // between 0 and 1

const updatePosition = () => {
  pos.x += (mouse.x - pos.x) * speed;
  pos.y += (mouse.y - pos.y) * speed;
  cursor.style.transform = "translate3d(" + pos.x + "px ," + pos.y + "px, 0)";
};

const updateCoordinates = (e) => {
  mouse.x = e.clientX;
  mouse.y = e.clientY;
};

window.addEventListener("mousemove", updateCoordinates);

function loop() {
  updatePosition();
  requestAnimationFrame(loop);
}

requestAnimationFrame(loop);
