export default function Menu(els) {
  const menu = document.querySelector(".video-popup");

  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      menu.classList.toggle("hidden");
    })
  );
}
